@tailwind base;
@tailwind components;
@tailwind utilities;

.message {
    font-family: 'Anonymous Pro', monospace;
    color: #C5C5C5;
    font-size: 40px;
    text-align: center;
    border-right: 2px solid rgba(255,255,255,.75);
    white-space: nowrap;
    overflow: hidden;
}

.mainMessage {
    font-family: 'Anonymous Pro', monospace;
    color: #C5C5C5;
    text-align: center;
    border-right: 2px solid rgba(255,255,255,.75);
    white-space: nowrap;
    overflow: hidden;
}

.projectItems {
    font-family: 'Anonymous Pro', monospace;
    font-size: 22px;
    text-align: center;
    color: #C5C5C5;
}

.grid-item:hover {
    color: #EA4C89;
    fill: #EA4C89;
}

.anim-typewriter-afternoon {
    animation: typewriter-afternoon 4s steps(44) 0.5s 1 normal both,
    blinkTextCursor-afternoon 500ms steps(44) infinite normal;
}

@keyframes typewriter-afternoon {
    from{width: 0;}
    to{width: 14.5em;}
}
@keyframes blinkTextCursor-afternoon {
    from{border-right-color: rgba(255,255,255,.75);}
    to{border-right-color: transparent;}
}

.anim-typewriter-MorningEvening {
    animation: typewriter-MorningEvening 4s steps(44) 0.5s 1 normal both,
    blinkTextCursor-MorningEvening 500ms steps(44) infinite normal;
}

@keyframes typewriter-MorningEvening {
    from{width: 0;}
    to{width: 13.2em;}
}
@keyframes blinkTextCursor-MorningEvening {
    from{border-right-color: rgba(255,255,255,.75);}
    to{border-right-color: transparent;}
}


.anim-typewriter-portfolio {
    animation: typewriter-portfolio 1.5s steps(14) 0.5s 1 normal both,
    blinkTextCursor-portfolio 500ms steps(14) infinite normal;
}

@keyframes typewriter-portfolio {
    from{width: 0;}
    to{width: 5.6em;}
}
@keyframes blinkTextCursor-portfolio {
    from{border-right-color: rgba(255,255,255,.75);}
    to{border-right-color: transparent;}
}

.viewBtn {
    background-color: #EA4C89;
    border-radius: 8px;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    line-height: 20px;
    margin: auto;
    padding: 10px 16px;
    transition: color 100ms;
}

.viewBtn:hover,
.viewBtn:focus {
    background-color: #F082AC;
}

.container {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }

.machine {
    width: 20vmin;
    fill: #EA4C89; }

.small-shadow, .medium-shadow, .large-shadow {
    fill: rgba(0, 0, 0, 0.05); }

.small {
    -webkit-animation: counter-rotation 2.5s infinite linear;
    -moz-animation: counter-rotation 2.5s infinite linear;
    -o-animation: counter-rotation 2.5s infinite linear;
    animation: counter-rotation 2.5s infinite linear;
    -webkit-transform-origin: 100.136px 225.345px;
    -ms-transform-origin: 100.136px 225.345px;
    transform-origin: 100.136px 225.345px; }

.small-shadow {
    -webkit-animation: counter-rotation 2.5s infinite linear;
    -moz-animation: counter-rotation 2.5s infinite linear;
    -o-animation: counter-rotation 2.5s infinite linear;
    animation: counter-rotation 2.5s infinite linear;
    -webkit-transform-origin: 110.136px 235.345px;
    -ms-transform-origin: 110.136px 235.345px;
    transform-origin: 110.136px 235.345px; }

.medium {
    -webkit-animation: rotation 3.75s infinite linear;
    -moz-animation: rotation 3.75s infinite linear;
    -o-animation: rotation 3.75s infinite linear;
    animation: rotation 3.75s infinite linear;
    -webkit-transform-origin: 254.675px 379.447px;
    -ms-transform-origin: 254.675px 379.447px;
    transform-origin: 254.675px 379.447px; }

.medium-shadow {
    -webkit-animation: rotation 3.75s infinite linear;
    -moz-animation: rotation 3.75s infinite linear;
    -o-animation: rotation 3.75s infinite linear;
    animation: rotation 3.75s infinite linear;
    -webkit-transform-origin: 264.675px 389.447px;
    -ms-transform-origin: 264.675px 389.447px;
    transform-origin: 264.675px 389.447px; }

.large {
    -webkit-animation: counter-rotation 5s infinite linear;
    -moz-animation: counter-rotation 5s infinite linear;
    -o-animation: counter-rotation 5s infinite linear;
    animation: counter-rotation 5s infinite linear;
    -webkit-transform-origin: 461.37px 173.694px;
    -ms-transform-origin: 461.37px 173.694px;
    transform-origin: 461.37px 173.694px; }

.large-shadow {
    -webkit-animation: counter-rotation 5s infinite linear;
    -moz-animation: counter-rotation 5s infinite linear;
    -o-animation: counter-rotation 5s infinite linear;
    animation: counter-rotation 5s infinite linear;
    -webkit-transform-origin: 471.37px 183.694px;
    -ms-transform-origin: 471.37px 183.694px;
    transform-origin: 471.37px 183.694px; }

@-webkit-keyframes rotation {
    from {-webkit-transform: rotate(0deg);}
    to   {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
    from {-moz-transform: rotate(0deg);}
    to   {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
    from {-o-transform: rotate(0deg);}
    to   {-o-transform: rotate(359deg);}
}
@keyframes rotation {
    from {transform: rotate(0deg);}
    to   {transform: rotate(359deg);}
}

@-webkit-keyframes counter-rotation {
    from {-webkit-transform: rotate(359deg);}
    to   {-webkit-transform: rotate(0deg);}
}
@-moz-keyframes counter-rotation {
    from {-moz-transform: rotate(359deg);}
    to   {-moz-transform: rotate(0deg);}
}
@-o-keyframes counter-rotation {
    from {-o-transform: rotate(359deg);}
    to   {-o-transform: rotate(0deg);}
}
@keyframes counter-rotation {
    from {transform: rotate(359deg);}
    to   {transform: rotate(0deg);}
}